<template>
  <section class="container970">
    <el-card>
      <div class="clubTop">
        <el-image :src="clubDet.company_logo"></el-image>
        <h3>{{ clubDet.company_name }}</h3>
      </div>
      <p>{{ clubDet.company_description }}</p>
    </el-card>
  </section>
</template>

<script>
export default {
  data() {
    return {
      clubDet: {},
      backgroundImage: require("@/assets/images/group/club.png")
    };
  },
  components: {},

  created() {
    this.getClub100Detail();
  },

  methods: {
    async getClub100Detail() {
      let res = await this.$http.getClub100Detail({
        id: this.$route.query.id
      });
      if (res.status == 200) {
        this.clubDet = res.data;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.el-card {
  padding: 20px;
  min-height: 600px;
  .clubTop {
    // display: flex;
    text-align: center;
    align-items: center;
    .el-image {
      height: 100px;
    }
    h3 {
      // margin-left: 45px;
      margin: 30px 0;
    }
  }
  p{
    // margin-top: 30px;
    font-size: 20px;
  }
}
</style>
